<template>
  <div id="leftMenu" :class="[isCollapse ? 'active' : '', theme]">
    <div class="header-wrap">
      <div class="header" :class="isCollapse ? 'fixed' : ''">
        <div class="logo" v-show="theme == 'dark'">
          <!--            <img :src="darkLogo" v-show="isCollapse"/>-->
          <!--            <img :src="miniLogo" v-show="!isCollapse"/>-->
          <!--          <span style="font-size: 15px;display: inline-block;margin-left: 10px;margin-bottom: 10px" >设备设施管理系统</span>-->
          <img class="left-item" :src="darkLogo" v-show="isCollapse"/>
          <img class="left-item" :src="miniLogo" v-show="!isCollapse"/>
          <span v-show="isCollapse" class="right-item" style="font-size: 17px">生产协同管理系统</span>
        </div>
        <div class="logo" v-show="theme == 'light'">
          <img :src="lightLogo" v-show="isCollapse"/>
          <img :src="miniLogo" v-show="!isCollapse"/>
          <span style="font-size: 15px">生产协同管理系统</span>
        </div>
      </div>
    </div>
    <!-- <div class="search-box">
      <el-input v-model="searchValue" placeholder="导航搜索" suffix-icon="el-icon-search"></el-input>
    </div>-->
    <el-menu
        :collapse-transition="false"
        router
        :background-color="backgroundColor"
        active-text-color="hsla(0,0%,100%,1)"
        text-color="hsla(0,0%,100%,0.7)"
        default-active="1-4-1"
        class="el-menu-vertical-demo"
        unique-opened
        @open="handleOpen"
        @close="handleClose"
        :collapse="!isCollapse"
    >
      <DsnMenuTree :data="list" :theme="theme"/>
    </el-menu>
  </div>
</template>


<script>
import {mapState} from "vuex";
import DsnMenuTree from "./dsn-menutree";
import {darkLogo, lightLogo, miniLogo} from "@/until/resource"

export default {
  components: {
    DsnMenuTree
  },
  data() {
    return {
      miniLogo,
      darkLogo,
      lightLogo,
      isCollapse: true,
      search: "",
      backgroundColor: "#191a23",
      textColor: "#fff",
      activeTextColor: "#2173dc"
    };
  },
  props: {
    theme: {
      type: String,
      default: "dark"
    }
  },
  computed: {
    ...mapState({
      list: state => {
        const arr = [
          {
            "children": [
              {
                label: "首页",
                id: "1",
                key: "welcomePage",
                url: "/welcome/welcomePage",
              },
              {
                label: "基础设置",
                id: "2",
                key: "baseSettings",
                permissionCode: 'menu:module:basicSetting',
                children: [
                  {
                    label: "储位管理",
                    id: "2-1",
                    key: "equipmentStockRefSettings",
                    permissionCode: 'api:storages:*',
                    url: '/baseSettings/equipmentStockRefSettings',
                  },
                  {
                    label: "设备分类",
                    id: "2-2",
                    name: 'equipmentCategory',
                    key: "equipmentCategory",
                    permissionCode: 'api:categories:*',
                    url: '/baseSettings/equipmentCategory',
                    children: null,
                  },

                  // {
                  //   label: "品牌管理",
                  //   id: "116",
                  //   key: "brandList",
                  //   url: '/baseSettings/brandList',
                  //   children: null,
                  // },
                  // {
                  //   label: "部门管理",
                  //   id: "15",
                  //   key: "departmentList",
                  //   url: '/baseSettings/departmentList',
                  //   children: null,
                  // },
                ]
              },
              {
                label: "设备管理",
                id: "3",
                key: "MES",
                permissionCode: 'menu:module:equipments',
                children: [
					{
					  label: "主档管理",
					  id: "3-1",
					  key: "mainGear",
					  permissionCode: 'api:equipments:*',
					  url: '/equipment/mainGear',
					  children: null,
					},
                  {
                    label: "设备管理",
                    id: "3-2",
                    key: "equipmentList",
                    permissionCode: 'api:equipments:*',
                    url: '/equipment/equipmentList',
                    children: null,
                  },
                  {
                    label: "设备转移(调拨)单",
                    id: "3-3",
                    key: "equipmentTransfer",
                    permissionCode: 'api:transferShift:*',
                    url: '/equipment/equipmentTransfer',
                    children: null,
                  },
                  // {
                  //   label: "设备转移",
                  //   id: "3-3",
                  //   key: "equipmentShift",
                  //   url: '/equipment/equipmentShift',
                  //   children: null,
                  // },
                  // {
                  //   label: "工单管理",
                  //   id: "31",
                  //   key: "equipmentList",
                  //   url: '/equipment/equipmentList',
                  //   children: null,
                  // }
                ]
              },
              {
                label: "设备盘点",
                id: "4",
                key: "business",
                permissionCode: "menu:module:inventoryCheck",
                children: [
                  // {
                  //   label: "设备设施条码打印",
                  //   labelI18n: "设备设施盘点计划",
                  //   id: "131",
                  //   key: "equipmentBarcodePrint",
                  //   url: '/business/equipmentBarcodePrint',
                  //   children: null,
                  // },
                  {
                    label: "盘点计划",
                    labelI18n: "盘点计划",
                    id: "3-5",
                    key: "equipmentInventorySchedule",
                    permissionCode: 'api:inventoryCheck:*',
                    url: '/business/equipmentInventorySchedule',
                    children: null,
                  },
                  // {
                  //   label: "设备设施盘报废",
                  //   labelI18n: "设备设施盘点计划",
                  //   id: "134",
                  //   key: "equipmentScrap",
                  //   url: '/business/equipmentScrap',
                  //   children: null,
                  // },
                ]
              },
              {
                label: "系统设置",
                id: "5",
                key: "system",
                permissionCode: 'menu:module:sysmtenSetting',
                children: [
                  {
                    label: "用户管理",
                    id: "2-3",
                    key: "userList",
                    url: '/baseSettings/userList',
                    permissionCode: 'api:users:*',
                    children: null,
                  },
                  {
                    label: "角色管理",
                    id: "2-4",
                    key: "roleList",
                    permissionCode: 'api:roles:*',
                    url: '/baseSettings/roleList',
                    children: null,
                  },

                ]
              }
              // {
              //   label: "报表",
              //   id: "5",
              //   key: "report",
              //   children: []
              // }
              // {
              //   label: "設備設施維護計劃",
              //   id: "5",
              //   key: "MES",
              //   children: [
              //     {
              //       label: "test3",
              //       id: "3",
              //       key: "MES",
              //       url: 'testIndex/testPage',
              //       children: null,
              //     }
              //   ]
              // },
              // {
              //   label: "數據分析",
              //   id: "6",
              //   key: "MES",
              //   children: [
              //     {
              //       label: "test3",
              //       id: "3",
              //       key: "MES",
              //       url: 'testIndex/testPage',
              //       children: null,
              //     }
              //   ]
              // },
              // {
              //   label: "S.O.P",
              //   id: "7",
              //   key: "MES",
              //   children: [
              //     {
              //       lable: "test3",
              //       id: "3",
              //       key: "MES",
              //       url: 'testIndex/testPage',
              //       children: null,
              //     }
              //   ]
              // },
              // {
              //   label: "報表管理",
              //   id: "8",
              //   key: "MES",
              //   children: [
              //     {
              //       lable: "test3",
              //       id: "3",
              //       key: "MES",
              //       url: 'testIndex/testPage',
              //       children: null,
              //     }
              //   ]
              // },
            ]
          }
        ]
        if (arr && arr.length == 1) {
          // return arr[0]["children"];
          return arr[0]["children"];
        }
        return [];
      }
    })
  },
  methods: {
    handleOpen() {
      // console.log(key, keyPath);
      this.$emit("handleCollapse", this.isCollapse);
    },
    handleClose() {
      // console.log(key, keyPath);
    },
    handleMenuCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit("handleCollapse", this.isCollapse);
    },
    toggle() {
      this.isCollapse = !this.isCollapse;
    }
  }
};
</script>
<style lang="scss">
@import "./Theme.scss";

$bg: #515a6e;
#leftMenu {
  .el-submenu__title {
    .el-submenu__icon-arrow {
      display: none;
    }

    span {
      display: none;
    }
  }

  &.active {
    .logo {
      img {
        width: auto;
        height: 50px;
      }
    }

    .el-submenu__title {
      .el-submenu__icon-arrow {
        display: initial;
      }

      span {
        display: initial;
      }
    }
  }

  .header-wrap {
    height: 64px;

    .header {
      z-index: 1;
    }
  }

  // .search-box {
  //   height: 40px;
  //   background-color: rgba(#ffffff, 0.03);
  //   padding: 6px 16px;
  //   .el-input {
  //     input {
  //       background: #101117;
  //       border: 1px solid #07070a;
  //       border-radius: 14px;
  //     }
  //     .el-input__inner {
  //       &::placeholder {
  //         font-family: PingFangSC-Regular;
  //         font-size: 12px;
  //         color: #909399;
  //       }

  //       &::-webkit-input-placeholder {
  //         /* WebKit browsers 适配谷歌 */
  //         font-family: PingFangSC-Regular;
  //         font-size: 12px;
  //         color: #909399;
  //       }

  //       &:-moz-placeholder {
  //         /* Mozilla Firefox 4 to 18 适配火狐 */
  //         font-family: PingFangSC-Regular;
  //         font-size: 12px;
  //         color: #909399;
  //       }

  //       &::-moz-placeholder {
  //         /* Mozilla Firefox 19+ 适配火狐 */
  //         font-family: PingFangSC-Regular;
  //         font-size: 12px;
  //         color: #909399;
  //       }

  //       &:-ms-input-placeholder {
  //         /* Internet Explorer 10+  适配ie*/
  //         font-family: PingFangSC-Regular;
  //         font-size: 12px;
  //         color: #909399;
  //       }
  //     }
  //   }
  // }
  .logo {
    width: 255px;
    height: 63px;
    line-height: 63px;
    color: white;
    text-align: center;
    padding: 5px 10px;
    vertical-align: middle;
    box-sizing: border-box;
    display: block;

    img {
      width: auto;
      height: 60px;
    }

    .right-item {
      width: 70%;
      height: 100%;
      margin-top: -10px;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
    }

    .left-item {
      width: 20%;
      height: 100%;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
    }
  }

  .el-submenu__title:hover,
  .el-submenu__title:focus {
    outline: none;
    background: $bg !important;
    opacity: 1;

    .title {
      color: #fff;
    }
  }

  position: fixed;
  width: 60px;
  height: 100%;
  background: $bg;
  left: 0;
  top: 0;
  padding: 0px 0px 10px 0px;
  overflow-y: auto;
  z-index: 1110;

  &::-webkit-scrollbar {
    //滚动条整体样式/
    width: 1px;
    height: 1px;
    //高宽分别对应横竖滚动条的尺寸/
    //height: 5px;
  }

  box-sizing: border-box;

  &.active {
    width: 256px;
  }

  .header {
    display: flex;
    justify-content: center;

    .el-input {
      padding: 3px 8px;

      .el-input__inner {
        width: 100%;
        border-radius: 0px;
      }
    }

    .el-icon-s-fold {
      font-size: 40px;
    }
  }

  .el-menu {
    border-right: 0px;
  }

  .el-menu--collapse {
    width: 60px;
  }

  i {
    color: #fff;
  }

  .el-menu-item:hover,
  .el-menu-item:focus,
  .el-menu-item.is-active {
    background-color: #2d8cf0 !important;
    opacity: 1;
  }
}

// 收缩菜单过长
.el-menu--vertical {
  top: 0 !important;
  overflow-y: scroll;
  height: 100%;
  background: #303133;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #999;
    background: #999;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px #999;
    border-radius: 10px;
    background: #ededed;
  }
}
</style>
