<template>
  <div id="dsnHeader" :class="[isOpen ? '' : 'close', HeaderTheme]">
    <div class="center">
      <i class="el-icon-s-fold header-item" @click="handleCloseOrOpen"></i>
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, index) in match" :key="index" v-show="item">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </div>
    <div class="right">
      <!-- 搜索框 -->
      <!-- <div class="right-item">
        <div class="search-box">
          <div class="search-icon">
            <i class="el-icon-search" @click="isSearch = !isSearch"></i>
          </div>
          <transition name="fade">
            <el-select
              v-model="searchValue"
              filterable
              remote
              reserve-keyword
              placeholder="search"
              @focus="handleFocus"
              :remote-method="remoteMethod"
              size="small"
              v-show="isSearch"
              @change="handleChange"
            >
              <el-option v-for="item in list" :key="item.key" :value="item.key">{{ item.lable }}</el-option>
            </el-select>
          </transition>
        </div>
      </div> -->

      <!-- 消息提示 -->


      <!-- 个人中心 -->
      <div class="right-item">
        <el-dropdown class="header-item">
          <span class="el-dropdown-link">
            <el-avatar
                size="small"
                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            ></el-avatar>
            <span class="user-name">{{ addForm.realname }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item>
              <i class="el-icon-user"></i> 个人中心
            </el-dropdown-item>-->
            <!-- <el-dropdown-item @click.native="Changepassword()">
              <i class="el-icon-unlock"></i> 修改密码
            </el-dropdown-item> -->
            <!-- <el-dropdown-item @click.native="PersonalinformationS()">
              <i class="el-icon-setting"></i> 个人设置
            </el-dropdown-item> -->
            <el-dropdown-item @click.native="loginOutDialogVisible = true">
              <i class="el-icon-refresh-right"></i> 退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 切换语言 -->
      <div class="right-item">
        <el-dropdown class="header-item" trigger="click" @command="handleCommandLanguage">
          <span class="el-dropdown-link">
            <i class="el-icon-picture-outline-round"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cn">简体中文</el-dropdown-item>
            <el-dropdown-item command="en" divided>English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- site切换 -->
      <!-- <div class="right-item">
        <el-dropdown class="header-item" trigger="click" @command="handleCommandSite">
          <span class="el-dropdown-link">site</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in siteList"
              :command="item.tenantSiteCode"
              :key="index"
            >{{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      <!-- 全屏 -->
      <!-- <i class="el-icon-full-screen header-item" @click="screen"></i> -->
      <!-- 更多 -->
      <!-- <div class="right-item">
        <el-button class="header-item" @click="drawer = true" type="text">
          <i class="el-icon-more"></i>
        </el-button>
      </div> -->

      <!-- 右侧抽屉 -->
      <el-drawer
          class="drawer-header"
          :visible.sync="drawer"
          direction="rtl"
          :before-close="handleClose"
          :modal-append-to-body="true"
          :append-to-body="true"
          size="300px"
      >
        <el-divider>主题风格设置</el-divider>
        <el-button
            class="header-setting-theme"
            type="text"
            @click="themeLeftMenu('dark')"
            :class="Theme === 'dark' ? 'active' : ''"
        >
          <img src="https://file.iviewui.com/admin-pro-dist/img/nav-theme-dark.da07f9c2.svg" alt/>
        </el-button>
        <el-button
            class="header-setting-theme"
            type="text"
            @click="themeLeftMenu('light')"
            :class="Theme === 'light' ? 'active' : ''"
        >
          <img src="https://file.iviewui.com/admin-pro-dist/img/nav-theme-light.262af236.svg" alt/>
        </el-button>
        <br/>
        <el-button
            class="header-setting-theme"
            type="text"
            @click="setHeaderTheme('light')"
            :class="HeaderTheme === 'light' ? 'active' : ''"
        >
          <img src="https://file.iviewui.com/admin-pro-dist/img/nav-theme-dark.da07f9c2.svg" alt/>
        </el-button>
        <el-button
            class="header-setting-theme"
            type="text"
            @click="setHeaderTheme('dark')"
            :class="HeaderTheme === 'dark' ? 'active' : ''"
        >
          <img src="https://file.iviewui.com/admin-pro-dist/img/header-theme-dark.1606ed02.svg" alt/>
        </el-button>
        <el-button
            class="header-setting-theme"
            type="text"
            @click="setHeaderTheme('blue')"
            :class="HeaderTheme === 'blue' ? 'active' : ''"
        >
          <img
              src="https://file.iviewui.com/admin-pro-dist/img/header-theme-primary.babcd2f1.svg"
              alt
          />
        </el-button>
        <el-divider>导航设置</el-divider>
        <div class="header-setting-item">
          <div class="header-setting-item-desc">固定侧边栏</div>
          <el-switch v-model="headerValue1"></el-switch>
        </div>
        <div class="header-setting-item">
          <div class="header-setting-item-desc">
            固定侧边栏
            <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top">
              <el-button type="text">
                <i class="el-icon-question"></i>
              </el-button>
            </el-tooltip>
          </div>
          <el-switch v-model="headerValue2"></el-switch>
        </div>
      </el-drawer>

      <!-- 退出登录确认框 -->
      <el-dialog
          title="退出"
          :visible.sync="loginOutDialogVisible"
          :modal-append-to-body="true"
          :append-to-body="true"
          width="450px"
          :show-close="false"
          v-el-drag-dialog
      >
        <div class="dialog-head">
          <i class="el-icon-question"></i> 退出登录确认
        </div>
        <p>您确定退出登录当前账户吗？打开的标签页和个人设置将不会保存。</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="loginOutDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="loginOut()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 设置弹出框 -->
      <el-dialog
          title="设置"
          :visible.sync="Personalinformation"
          width="600px"
          :modal-append-to-body="true"
          :append-to-body="true"
          :show-close="false"
          v-el-drag-dialog
      >
        <el-form
            :model="addForm"
            :label-width="formLabelWidth"
            ref="addForm"
            :validate-on-rule-change="false"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户CODE" prop="deviceCode">
                <dsn-input placeholder="请输入用户CODE" :disabled="true" v-model="addForm.name"></dsn-input>
              </el-form-item>
            </el-col>

            <!-- 3 -->
            <!-- <el-col :span="12">
              <el-form-item label="中文姓：" prop="rotateSpeed">
                <dsn-input placeholder="请输入中文姓"></dsn-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item label="用户姓名:" prop="commonName">
                <el-input v-model.trim="addForm.commonName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="外文姓：" prop="deviceFloat">
                <dsn-input placeholder="请输入外文姓" v-model="addForm.foreignSurname"></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="外文名：" prop="artificialFloat">
                <dsn-input placeholder="请输入外文名" v-model="addForm.foreignCommonName"></dsn-input>
              </el-form-item>
            </el-col>
            <!-- 2 -->
            <el-col :span="12">
              <el-form-item label="性别：" placeholder="请选择">
                <el-select style="width:100%" size="small" v-model="addForm.gender">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 2 -->
            <el-col :span="12">
              <el-form-item label="员工编号：" prop="deviceFloat">
                <dsn-input placeholder="请输入员工编号" v-model="addForm.staffNum"></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户图标：" prop="artificialFloat">
                <dsn-input placeholder="请输入用户图标"></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话号码：" prop="deviceFloat">
                <dsn-input placeholder="请输入电话号码" v-model="addForm.phone"></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电子邮箱：" prop="artificialFloat">
                <dsn-input placeholder="请输入电子邮箱" v-model="addForm.email"></dsn-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型：" prop="deviceFloat">
                <dsn-select style="width:100%" v-model="addForm.idType">
                  <el-option
                      v-for="item in cardType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </dsn-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生日:" prop="birthday">
                <el-date-picker
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    v-model="addForm.birthday"
                    type="date"
                    placeholder="选择日期"
                    size="small"
                    style="width:180px"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证件号码：" prop="artificialFloat">
                <dsn-input placeholder="请输入证件号码" v-model="addForm.idNumber"></dsn-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="Personalinformation = false">取 消</el-button>
          <el-button type="primary" @click="handleSave()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改密码弹窗 -->
      <el-dialog
          title="修改密码"
          :visible.sync="passwordwindow"
          width="400px"
          :modal-append-to-body="true"
          :append-to-body="true"
          :show-close="false"
          v-el-drag-dialog
      >
        <el-form
            :model="modifyform"
            :label-width="formLabelWidth"
            ref="modifyform"
            :rules="addRules"
            :validate-on-rule-change="false"
        >
          <el-form-item label="旧密码:" prop="oldPassword">
            <dsn-input
                placeholder="请输入旧密码"
                v-model="modifyform.oldPassword"
                autocomplete="new-password"
            >
              <i slot="suffix" class="el-icon-view" @click="showPwd"></i>
            </dsn-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="passwordcheckModel">
            <dsn-input
                placeholder="请输入新密码:"
                v-model="modifyform.passwordcheckModel"
                :type="pwdType"
                autocomplete="new-password"
            >
              <i slot="suffix" class="el-icon-view" @click="showPwd"></i>
            </dsn-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="newPassword">
            <dsn-input
                placeholder="请再输入一次密码"
                v-model="modifyform.newPassword"
                :type="pwdType"
                autocomplete="new-password"
            >
              <i slot="suffix" class="el-icon-view" @click="showPwd"></i>
            </dsn-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordwindow = false">取 消</el-button>
          <el-button type="primary" @click="modifyformpassword()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import screenfull from "screenfull";
// import notificatItem from "./dsn-notificat-item";
import {mapActions, mapGetters, mapState} from "vuex";
import {clearToken, getToken, toLogin} from "@/until/action";
import {changeSiteHttp, getSystemList, updateUser} from "@/api/login.api.js";
import _ from "lodash";
import {updateUserPwd} from "@/api/efms/users/users.api";
// const siteList = [
//   // { key: 1, name: "深圳" },
//   // { key: 2, name: "上海" },
//   // { key: 3, name: "北京" },
// ];
export default {
  name: "dsnHeader",
  components: {
    // notificatItem
  },
  data() {
    const newPasswordNum = (rule, value, callback) => {
      let pattern = new RegExp(
          "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d$@$!%*#?&_.]{8,}$"
      );
      if (value === "" || value === null) {
        callback("密码不能为空");
      } else if (!pattern.test(value)) {
        callback("密码不符合格式");
      } else if (value.length > 50) {
        callback("不能超过50位");
      } else if (value.length < 8) {
        callback("不能低于8位");
        callback();
      }
    };
    return {
      pwdType: "password",
      addRules: {
        newPassword: [
          {validator: newPasswordNum, trigger: "blur", required: true}
        ],
        oldPassword: [
          {message: "旧密码不能为空", trigger: "blur", required: true}
        ],
        passwordcheckModel: [
          {message: "新密码不能为空", trigger: "blur", required: true}
        ]
      },
      passwordwindow: false,
      //修改密码
      modifyform: {
        oldPassword: "",
        passwordcheckModel: "",
        newPassword: ""
      },
      // 证件类型下拉框
      cardType: [
        {name: "居民身份证", value: "居民身份证"},
        {name: "居住证", value: "居住证"},
        {name: "签证", value: "签证"},
        {name: "护照", value: "护照"},
        {name: "户口本", value: "户口本"},
        {name: "军人证", value: "军人证"},
        {name: "学生证", value: "学生证"},
        {name: "台港澳通行证", value: "台港澳通行证"},
        {name: "其他", value: "其他"}
      ],
      //性别下拉框
      options: [
        {
          value: "MALE",
          label: "男"
        },
        {
          value: "FEMALE",
          label: "女"
        }
      ],
      Personalinformation: false, //设置弹窗
      formLabelWidth: "100px",
      //设置弹窗
      addForm: {
        id: null,
        username: null,
        realname: null,
        gender: "",
        idType: "",
        birthday: "",
        name: "",
        staffNum: "",
        phone: "",
        email: "",
        idNumber: "",
        foreignSurname: "",
        foreignCommonName: "",
        commonName: ""
      },
      treeArr: [],
      list: [],
      lang: "cn",
      match: [], // 当前路由
      loginOutDialogVisible: false,
      isOpen: true,
      drawer: false, // 右侧更多
      Theme: "dark", // 左侧皮肤
      HeaderTheme: "light", // 头部皮肤
      headerValue1: true,
      headerValue2: true,
      activeName: "second", // 消息tab
      message: [
        {
          circleUrl:
              "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        },
        {
          circleUrl:
              "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        }

        // {circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'},
        // {circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'},
        // {circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'},
        // {circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'},
        // {circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'},
      ],
      // site下拉框数据
      // siteList,
      // 搜索框的值
      searchValue: "",
      // 是否展开搜索框
      isSearch: false
      // siteList: [],
    };
  },
  watch: {
    $route() {
      this.match = [];
      this.$route.matched.forEach(element => {
        this.match.push(element.meta.title);
      });
    },
    siteList(value) {
      console.log(value, "watch");
      return value;
    }
  },
  computed: {
    ...mapState({
      // 待办通知
      agendaList: state => {
        const arr = state["headerMessage"]["agendaList"];
        if (arr.length) {
          return arr;
        }
        return [];
      },
      // 关注通知
      attentionList: state => {
        const arr = state["headerMessage"]["attentionList"];
        if (arr.length) {
          return arr;
        }
        return [];
      },
      // 消息通知
      notificationList: state => {
        const arr = state["headerMessage"]["notificationList"];
        if (arr.length) {
          return arr;
        }
        return [];
      }
    }),
    notifiCount() {
      return (
          this.agendaList.length +
          this.attentionList.length +
          this.notificationList.length || 0
      );
    },
    ...mapGetters(["userinfo", "siteList"])
  },
  created() {
    this.$nextTick(() => {
      console.log(mapState([]));
    });
    this.getSiteList();
    this.information();
  },
  methods: {
    ...mapActions(["getUserInfo"]),

    screen() {
      // screenfull.toggle();
      this.$emit("handlerFullOpen");
    },
    //查看密码
    showPwd() {
      this.pwdType === "password"
          ? (this.pwdType = "")
          : (this.pwdType = "password");
      let e = document.getElementsByClassName("el-icon-view")[0];
      this.pwdType == ""
          ? e.setAttribute("style", "color: #409EFF")
          : e.setAttribute("style", "color: #c0c4cc");
    },
    modifyformpassword() {
      if (this.modifyform.newPassword === this.modifyform.passwordcheckModel) {
        const password = {
          newPassword: this.modifyform.newPassword,
          oldPassword: this.modifyform.oldPassword
        };

        // const password = { params };
        // modifyUserInfo(password).then(data => {
        //   const res = data.data;
        //   this.$message({
        //     message: res.code === 200 ? "操作成功" : res.message,
        //     type: res.code === 200 ? "success" : "error"
        //   });
        //
        //   if (res.code === 200) {
        //     this.passwordwindow = false;
        //   }
        //   return;
        // });
        updateUserPwd(password).then(result => {
          const res = data.data;
          this.$message({
            message: res.code === 200 ? "操作成功" : res.message,
            type: res.code === 200 ? "success" : "error"
          });
          if (res.code === 200) {
            this.passwordwindow = false;
          }
          return;
        })
      } else {
        this.$message({
          message: "两次密码不一致",
          type: "error"
        });
      }
    },
    //修改密码
    Changepassword() {
      (this.oldPassword = ""),
          (this.newPassword = ""),
          (this.modifyform.passwordcheckModel = ""),
          (this.passwordwindow = true);
    },
    //修改信息
    handleSave() {
      const payload = {
        ...this.addForm
        // userGroupIds: this.leftTransferValue,
        // roleIds: this.rightTransferValue
      };

      const newPayload = {...payload, id: this.addForm.id};
      updateUser(newPayload).then(data => {
        const res = data.data;
        this.$message({
          message: res.code === 200 ? "操作成功" : res.message,
          type: res.code === 200 ? "success" : "error"
        });

        if (res.code === 200) {
          this.Personalinformation = false;
        }
        return;
      });
    },
    //获取当前用户信息
    information() {
      const data = {};
      // getCurrentUserInfo(data).then(data => {
      //   const res = data.data;
      //   if (res.code == 200) {
      //     this.addForm = res.data;
      //   }
      // });

      // this.getUserInfo(null);
      let userProfileJson = localStorage.getItem("userProfile")
      if (userProfileJson != null) {
        let user = JSON.parse(userProfileJson);
        this.addForm = {
          gender: user.sex,
          username: user.username,
          realname: user.realname,
          idType: user.userIdentity,
          birthday: user.birthday,
          name: user.realname,
          staffNum: user.workNo,
          phone: user.phone,
          email: user.email,
          idNumber: null,
          foreignSurname: null,
          foreignCommonName: null,
          commonName: null,
        }
      }
      // this.addForm = {
      //   gender: "男",
      //   idType: "",
      //   birthday: "1993-09-09",
      //   name: "charlie",
      //   staffNum: "",
      //   phone: "",
      //   email: "",
      //   idNumber: "1101",
      //   foreignSurname: "charlie",
      //   foreignCommonName: "charlie",
      //   commonName: "charlie"
      // }
    },
    //设置
    PersonalinformationS() {
      this.Personalinformation = true;
      this.information();
    },
    handleChange(key) {
      this.searchValue = "";
      this.$router.push({name: key});
    },
    handleFocus() {
      this.list = [];
    },
    remoteMethod(key) {
      if (!key) {
        this.list = [];
        return;
      }

      const pid = this.appMenuList[0].parentId;
      const cloneDeepList = _.cloneDeep(this.appMenuList);
      this.treeArr = [];
      this.expanceTree(cloneDeepList, pid);
      const arr = this.treeArr.filter(item => {
        return (
            item.lable.indexOf(key) != -1 && item.hasParent && !item.children
        );
      });
      this.list = arr;
    },
    expanceTree(list) {
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.expanceTree(item.children, item.id);
        }
        this.treeArr.push(item);
      });
    },
    // 获取用户site列表
    getSiteList() {
      // getSiteListHttp().then();
    },
    handleCommandLanguage(command) {
      this.$i18n.locale = command;
      // 切换语言，同步配置
      changeSiteHttp().then();
    },
    handleCommandSite(command) {
      // console.log(command);
      // const token = getToken()
      // console.log(token)
      // 切换site，同步配置
      changeSiteHttp({sessionId: getToken(), tenantSiteCode: command}).then(
          data => {
            const res = data.data;
            if (res.code === 200) {
              console.log(res.data);
              const type = "MES";
              getSystemList().then(data => {
                const res = data.data;
                if (res.code == 200) {
                  const arr = res.data;
                  const item = arr.find(item => {
                    return item.key == type;
                  });
                  this.getUserInfo(item.id);
                }
              });
              return;
            }
            this.notification().error({
              body: res.message
            });
          }
      );
    },
    handleCommand(command) {
      this.$i18n.locale = command;
    },
    switchLang() {
      this.$i18n.locale = this.lang;
    },
    handleCloseOrOpen() {
      this.isOpen = !this.isOpen;
      this.$emit("handleCloseOrOpen");
    },
    handleClickMessage() {
      // console.log(tab, event)
    },
    handleClose() {
      // console.log("close");
      this.drawer = false;
    },
    // 设置左侧主题
    themeLeftMenu(theme) {
      this.Theme = theme;
      this.$emit("themeLeftMenu", theme);
    },
    // 设置头部主题
    setHeaderTheme(id) {
      this.HeaderTheme = id;
    },
    // 退出登录
    loginOut() {
      // console.log("loginOUt");
      this.loginOutDialogVisible = false;
      clearToken();
      toLogin();
      // this.$router.push({
      //   name: "login"
      // });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./Theme.scss";

.date {
  font-size: 12px;
  color: #808695;
}

::v-deep .el-popper {
  padding: 0;

  .el-tabs__header {
    margin-bottom: 0;
  }
}

::v-deep .el-tabs__header {
  margin-bottom: 0;
}

::v-deep .message_wrap {
  .message_list {
    max-height: 400px;
    overflow: auto;
  }

  .message_item {
    display: flex;
    padding: 12px 24px;
    border-bottom: 1px solid #e8eaec;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    text-align: left;

    &:hover {
      background-color: #f0faff;
    }

    .message_item-right {
      margin-left: 15px;

      h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #515a6e;
      }

      span {
        font-size: 12px;
        color: #808695;
      }
    }
  }
}

.drawer-header {
  ::v-deep .el-drawer__header {
    margin-bottom: 0;
  }

  ::v-deep .el-drawer__body {
    padding: 0 20px 20px;
  }

  ::v-deep .header-setting-item {
    display: flex;
    align-items: center;
    padding: 12px 0;

    .header-setting-item-desc {
      flex: 1 1;
      font-size: 14px;
    }
  }

  .el-icon-question {
    font-size: 15px;
  }

  .header-setting-theme {
    margin-right: 10px;

    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: transparent;
      margin: 10px auto 0 auto;
    }

    &.active::after {
      background: #19be6b;
    }
  }
}

::v-deep .el-dialog {
  border-radius: 6px;
  background-clip: padding-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  .el-dialog__header {
    height: 24px;
    font-size: 18px;
    padding: 16px;
    border-radius: 4px 4px 0px 0px;
  }

  .dialog-head {
    i {
      font-size: 22px;
      color: #f90;
      margin-right: 10px;
    }

    color: #17233d;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}
</style>
<style lang="scss">
#dsnHeader {
  position: fixed;
  height: 64px;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 256px;
  z-index: 1090;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;

  &.close {
    padding-left: 61px;
  }

  .center {
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;

    .el-icon-s-fold {
      margin-right: 15px;
      height: 64px;
      line-height: 64px;
      font-weight: normal;
      font-size: 18px;
      color: #515a6e;
      cursor: pointer;
    }
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;

    & i {
      font-size: 18px;
      color: #515a6e;
      font-weight: normal;
    }

    .el-badge {
      .el-badge__content {
        top: 16px;
        left: 0;
        right: auto;
      }
    }

    .el-dropdown-link {
      display: inline-flex;
      align-items: center;
    }

    .right-item {
      height: 100%;
      line-height: 64px;

      .user-name {
        margin-left: 12px;
        font-size: 14px;
      }

      .el-avatar--small {
        width: 24px;
        height: 24px;
      }

      .search-box {
        display: flex;
        overflow: hidden;

        .el-icon-search {
          font-size: 20px;
          vertical-align: sub;
        }

        input {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid rgb(217, 217, 217);
        }

        // 动画
        .fade-enter-active {
          animation: fade-in 0.4s;
          // transition: all 0.6s ease;
          // width: 188px;
          // opacity: 1;
        }

        .fade-leave-active {
          // transition: all 0.6s ease;
          // width: 188px;
          // opacity: 0;
          animation: fade-in 0.5s reverse;
        }

        @keyframes fade-in {
          0% {
            // transform: scale(0);
            width: 0px;
          }

          100% {
            // transform: scale(1);
            width: 203px;
          }
        }
      }
    }
  }
}
</style>
