import {VUE_APP_COMPANY} from '@/config'
export const miniLogo = VUE_APP_COMPANY =='DSN'? require("@/assets/logo.mini.png"): require("@/assets/logo_transprent.jpg");
// export const darkLogo = VUE_APP_COMPANY =='DSN'? require("@/assets/logo.7b8cc895.dark.png"):require("@/assets/logo_title_transprent.jpg");
export const lightLogo = VUE_APP_COMPANY =='DSN'?require("@/assets/logo.7b8cc895.png"):require("@/assets/logo_title_transprent.jpg");


export const darkLogo = VUE_APP_COMPANY =='DSN'? require("@/assets/logo.mini.png"):require("@/assets/logo.mini.png");



