<template>
  <div class="menu-tree">
    <label :key="item.id" v-for="(item,i) in data">
<!--      <el-submenu :index="item.id" v-if="item.children" v-has="item.permissionCode" :popper-class='theme'>-->
      <el-submenu :index="item.id" v-if="item.children"  v-has="item.permissionCode" :popper-class='theme'>
        <template slot="title">
          <i class="el-icon-location" style="font-size:25px;"></i>
          <span slot="title" style="font-size:17px;font-family: '微软雅黑',serif">{{ item.label }}</span>
        </template>
        <DsnMenutree :data="item.children"/>
      </el-submenu>
<!--      v-has="item.permissionCode"-->

      <el-menu-item
          :default-active="defaultActive"
          v-has="item.permissionCode"
          style="font-size:17px;font-family: '微软雅黑',serif"
          v-else
          :route="{
            name: item.key,
          }"
          :key="i"
          :index="item.key"
      >{{ item.label }}
      </el-menu-item>
    </label>
  </div>
</template>

<script>
import DsnMenutree from "@/views/layout/dsn-menutree";

export default {
  name: "DsnMenutree",
  data() {
    return {
      menu_data: {}
    };
  },
  computed: {
    defaultActive() {
      let defaultName = this.$route.name;
      if (this.data != null && this.data.length > 0) {
        this.data.forEach(v => {
          if (v.children != null&&v.children.length>0) {
            let target = v.children.filter(s => s["key"] === this.$route.name);
            if (target!=null&&target.length>0){
              defaultName = target[0].key;
            }
          }
        })
      }
      return defaultName
    }
  },
  components: {
    DsnMenutree: DsnMenutree
  },
  props: ["data", 'theme']
};
</script>
