import request from "@/service/";
import qs from "querystring";


//login
export const login = (data) => {
  return request.post(`${window.VUE_APP_URL}/sso/cus/login`, data);
};


export const login2 = (data) => {
  return request.post(`${window.VUE_APP_URL}/jeecg-boot/sys/login`, data);
};


// type SYSTEM 可以查询
export const getSystemList = (data) => {
  return request.get(
    `${window.VUE_APP_URL}/mcs/open/resource/getSystemList`,
    data
  );
};

export const getUser = (data) => {
  let params = qs.stringify(data);
  return request.post(`${window.VUE_APP_URL}/sso/cus/userInfo?${params}`);
};

/**
 * 获取用户site列表(查询用户下的工厂信息)
 * @param {*} data
 */
export const getSiteListHttp = (data) => {
  return request.get(`${window.VUE_APP_URL}/mcs/site/findByUser`, {
    params: data,
  });
};

/**
 * 根据key取搜索菜单
 * @param {*} data
 */
export const searchMenuByKeyHttp = (data) => {
  let params = qs.stringify(data);
  return request.post(`${window.VUE_APP_URL}/sso/cus/userInfo?${params}`);
};

/**
 * 切换site
 * @param {*} data
 */
export const changeSiteHttp = (data) => {
  let params = qs.stringify(data);
  return request.post(
    `${window.VUE_APP_URL}/sso/cus/changeSite?${params}`,
    data
  );
};

/**
 * 获取租户的list
 * @param {*} data
 */
export const getTeanListByUser = (data) => {
  let params = qs.stringify(data);
  return request.post(`${window.VUE_APP_URL}/sso/cus/userInfo?${params}`);
};

/**
 * 获取site 的list
 * @param {*} data
 */
export const getSiteListByteanSiteCode = (data) => {
  let params = qs.stringify(data);
  return request.post(`${window.VUE_APP_URL}sso/cus/userInfo?${params}`);
};

/**
 *  根据site  租户 还有  登录名称获取系统 的list
 *  使用在下拉框
 * @param {*} data
 */
export const getSystemListByInfo = (data) => {
  let params = qs.stringify(data);
  return request.post(`${window.VUE_APP_URL}sso/cus/userInfo?${params}`);
};

// 新的登录接口
export const loginConfig = (data) => {
  return request.post(
    `${window.VUE_APP_URL}/sso/cus/loginConfig?tenantCode=${data.tenantCode}`,
    data
  );
};

/**
 * 获取可选登录配置
 * /cus/loginConfigInfo
 */
export const loginConfigInfoHttp = (data) => {
  return request.post(
    `${window.VUE_APP_URL}/sso/cus/loginConfigInfo?tenantCode=${data.tenantCode}`,
    data
  );
};

export const loginConfigInfoHttpV2 = (data) => {
  return request.post(
    `${window.VUE_APP_URL}/jeecg-boot/sys/login`,
    data
  );
};


export const getLoginCheckKey =(data)=>{
  return request.get(`${window.VUE_APP_URL}/jeecg-boot/sys/randomImage/${data.key}`,{});
}

export const getCurrentUserInfo = (data) => {
  return request.get(`${window.VUE_APP_URL}/mcs/getCurrentUserInfo`, data);
};

// /user/updateUser/{id}
// 单条：更新用户信息
export const updateUser = (data) => {
  return request.put(
    `${window.VUE_APP_URL}/mcs//user/updateUser/${data.id}`,
    data
  );
};

//修改密码
///password/modify
//修改用户密码
export const modifyUserInfo = (data) => {
  return request.put(`${window.VUE_APP_URL}/mcs/password/modify`, {
    password: data,
  });
};
